<template>
  <v-dialog
      v-model="dialog"
      width="800px">
    <v-card>
      <v-card-title>
        <span>Review SchoolPay application</span>
        <hr>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <h3>School contact</h3>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>School name: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.school_name}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Email: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.email}}
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="school_pay.website">
            <v-col cols="12" md="6">
              <b>Website: </b>
            </v-col>
            <v-col cols="12" md="6">
             <a :href="school_pay.website" target="_blank">{{school_pay.website}}</a>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Full school address: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.address_line_1}} {{school_pay.address_line_2}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Suburb/town: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.suburb}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Post code: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.post_code}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Phone: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.phone}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Other phone: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.alt_phone}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <h3>Finance contact person</h3>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Contact person full name: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.contact_person_full_name}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Contact person email: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.contact_person_email}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Contact person phone: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.contact_person_phone}}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <h3>Score approval</h3>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="currentUser && currentUser.access_type != 'score'">
            <v-col cols="12" md="6">
              <b>Score approved: </b>
            </v-col>
            <v-col cols="12" md="6">
               <span class="badge"
                     v-bind:class="{ 'badge-success': school_pay.score_approval == 'approved', 'badge-warning': school_pay.score_approval == 'not_approved' }"
               >{{ school_pay.score_approval_text }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <b>Score approved decision by: </b>
            </v-col>
            <v-col cols="12" md="6">
               <span>
               {{ school_pay.scoreApprovalDecisionBy }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <b>Score remarks: </b>
            </v-col>
            <v-col cols="12" md="6">
               <span v-html="school_pay.score_approval_remarks"></span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="12">
              Remarks <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="school_pay.admin_remarks"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.school_pay.admin_remarks.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <h5>Enroller remarks</h5>
            </v-col>
            <v-col cols="12" md="6">
              <div v-if="school_pay.user_remarks" v-html="school_pay.user_remarks"></div>
              <div v-else>
                NA
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            class="cancel-btn"
            text
            @click="dialog = false"
        >
          Close
        </v-btn>

        <v-btn
            dark
            x-large
            @click="rejected"
        >
          Decline
        </v-btn>

        <v-btn
            class="btn btn-primary"
            dark
            x-large
            @click="approved"
        >
        Approve
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";

import SchoolPayApplicationService from "@/services/school-pay/SchoolPayApplicationService";

const schoolPayApplication = new SchoolPayApplicationService();
export default {
  name: "ApprovedOrRejected",
  data() {
    return {
      dialog: false,
      currentUser: {},
      school_pay: {
        admin_remarks: ''
      },
      editorConfig: {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'Source', '-', 'Save', 'NewPage', 'DocProps', 'Preview', 'Print', '-', 'Templates'],
        ],
        versionCheck: false
      }
    }
  },
  validations: {
    school_pay: {
      admin_remarks: { required }
    }
  },
  methods: {
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    openDialog(school_pay) {
      this.dialog = true;
      this.school_pay = school_pay;
      this.getCurrentUser();
    },
    approved() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.school_pay.application_status = 'approved';
        schoolPayApplication
            .approvedOrRejected(this.school_pay.id, this.school_pay)
            .then((response) => {
              this.$snotify.success("SchoolPay application approved");
              this.$emit('refresh');
              this.dialog = false;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    rejected() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.school_pay.application_status = 'rejected';
        schoolPayApplication
            .approvedOrRejected(this.school_pay.id, this.school_pay)
            .then((response) => {
              this.$snotify.success("SchoolPay application not approved");
              this.$emit('refresh');
              this.dialog = false;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
  }
}
</script>

<style scoped>

</style>